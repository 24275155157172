var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $contentErrors = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $languageIDInput = $('input[name="LANGUAGE_ID"]', $emailContainerNode);
        var $emailError = $('.site-email-signup__message__error__email_address', $emailContainerNode);
        var $errorMessages = $('.error-message', $emailContainerNode);
        var $showEmailPromo =
          typeof $emailContainerNode.data('show-email-promotion') == 'undefined'
            ? false
            : $emailContainerNode.data('show-email-promotion');

        if (Drupal.settings.common.has_onelink_trans) {
          if (generic.cookie('LOCALE') === Drupal.settings.common.locate_one_link_translation) {
            $languageIDInput.val(Drupal.settings.common.lang_id_one_link_translation);
          }
        }

        if ($showEmailPromo) {
          var $emailTermsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);
          var $emailTermsError = $('.site-email-signup__message__error__email_checkbox', $emailContainerNode);
          var $emailTermsLabel = $('.site-email-signup__checkbox__text', $emailContainerNode);
          var $emailFormContainer = $('.site-email-signup__content', $emailContainerNode);

          // Show terms + conditions
          $emailInput.on('focus', function (e) {
            $emailTermsAndConditions.slideDown(300);
          });

          // hide terms + conditions when clicked outside
          $(document).click(function (hideEvt) {
            if (!$(hideEvt.target).closest($emailFormContainer).length && !$(hideEvt.target).is($emailFormContainer)) {
              if ($emailTermsAndConditions.is(':visible')) {
                $emailTermsAndConditions.slideUp(300);
              }
            }
          });
        }

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $errorMessages.addClass('hidden');
          $contentErrors.addClass('hidden');
          $emailSuccess.add($contentErrors).addClass('hidden');
          $emailInput.removeClass('error');
          $emailError.removeClass('error');

          if ($showEmailPromo) {
            $emailTermsLabel.removeClass('error');
          }

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          // if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
          //   params.SMS_PROMOTIONS = 1;
          // }
          if ($showEmailPromo) {
            if ($emailContainerNode.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
              params.PC_EMAIL_PROMOTIONS = 1;
            } else {
              params.PC_EMAIL_PROMOTIONS = 0;
            }
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              if (_.isNull($.cookie('displaySignUp'))) {
                $.cookie('displaySignUp', '1', {
                  expires: 1,
                  path: '/'
                });
              }
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                // Close active utility items
                site.globalNavigation.closeSubNav(0);
                $emailInput.val('');
                // $mobileNumberInput.val('');
                // $termsAndConditions.slideUp(0);
                if ($showEmailPromo) {
                  $emailInput.val('');
                  $emailTermsAndConditions.slideUp(0);
                }
                generic.overlay.launch({
                  height: 'auto',
                  cssClass: 'site-email-signup__success-overlay',
                  fixed: true,
                  html: $emailSuccess.html()
                });
              }
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();

              for (var i = 0; i < errObjs.length; i++) {
                var myErr = errObjs[i];

                if (myErr && myErr.key) {
                  $contentErrors.removeClass('hidden');
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    $emailInput.addClass('error');
                    $emailError.removeClass('hidden');
                  }
                  if ($showEmailPromo) {
                    if (
                      myErr.key === 'required_dependency.pc_email_promotions.pc_email_address.dependency.email_signup'
                    ) {
                      $emailInput.addClass('error');
                      $emailError.removeClass('hidden');
                    }
                    if (
                      myErr.key === 'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup'
                    ) {
                      $emailTermsLabel.addClass('error');
                      $emailTermsError.removeClass('hidden');
                    }
                  }
                }
              }
              generic.focusErrors($contentErrors, $emailForm);
            }
          });
        });
      });
    },

    initBBLink: function () {
      // Will validate user signed in or not and change url of bbrewards icon accordingly, in header
      var signedIn = site.userInfoCookie.getValue('signed_in') - 0 === 1;
      var $site_header_signup = $('.js-site-header__signup-unsigned');
      var data_normal_link = $site_header_signup.attr('data-normal-link');
      var data_loyalty_link = $site_header_signup.attr('data-loyalty-link');

      if ($site_header_signup.length) {
        if (signedIn) {
          $site_header_signup.attr('href', data_loyalty_link);
        } else {
          $site_header_signup.attr('href', data_normal_link);
        }
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      // Check he is signed in and he is a loyalty member
      generic.jsonrpc.fetch({
        method: 'user.fullData',
        params: [{}],
        onSuccess: function (data) {
          var user = data.getValue();

          if (user.has_loyalty_program) {
            site.emailSignup.initBBLink();
          } else {
            var $site_header_signup = $('.js-site-header__signup-unsigned');

            $site_header_signup.hide();
          }
        }
      });
    }
  };
})(jQuery);
